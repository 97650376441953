import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router'
import JoinA from '../components/joina'
import JoinB from '../components/join'
import Axios from 'axios'
import NotFoundPage from './404'
import { graphql } from 'gatsby'
import { baseURL, ENDPOINTS } from '../../src/constants'

const JoinRoute = (props: { data: any }) => {
  const [joinPage, setJoinPage] = useState(1)

  const getJoinPages = async () => {
    const joinpages = await Axios.get(baseURL + ENDPOINTS.joinPages)
    const settingValue = joinpages.data.data[0].setting_value
    for (let i = 0; i < settingValue.length; i++) {
      if (settingValue[i].enable) {
        setJoinPage(settingValue[i].id)
      }
    }
  }
  useEffect(() => {
    getJoinPages()
  }, [])
  return (
    <Router basepath="/join">
      {joinPage === 1 ? (
        <JoinA path="/*" data={props.data} />
      ) : (
        <JoinB path="/*" data={props.data} />
      )}
      <NotFoundPage default withLayout={false} />
    </Router>
  )
}

export default JoinRoute

export const query = graphql`
  query {
    allImageSharp(
      filter: {
        fluid: {
          originalName: {
            in: [
              "shop-millions-of-products.png"
              "create-share-link.png"
              "your-shopping-board.png"
              "chirpyest-curated-style-finds.png"
              "inspiration-on-our-blog.png"
              "chirpyest-works-on-mobile.png"
            ]
          }
        }
      }
      sort: { order: ASC, fields: fluid___originalName }
    ) {
      edges {
        node {
          id
          fluid(maxHeight: 1024, quality: 100) {
            src
            srcSet
            base64
            aspectRatio
            originalImg
            originalName
            sizes
          }
        }
      }
    }
  }
`
